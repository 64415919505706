import React from 'react';

import { Container } from '../../../../symphony';

import { SlNavbarThemes } from '../../../../shared/public/globalInterfaces/globalInterfaces';
import { WhySolo } from '../../components/WhySolo/WhySolo';
import { SignUp2 } from '../../components/SignUp2/SignUp2';
import { DreamJob } from '../../components/DreamJob/DreamJob';
import { Awards } from '../../components/Awards/Awards';
import { Courses } from '../../components/Courses/Courses';
import { WhyCode } from '../../components/WhyCode/WhyCode';
import { ProPage } from '../../components/ProPage/ProPage';
import { Header } from '../../components/Header/Header';
import { PerfectPlatform } from '../../components/PerfectPlatform/PerfectPlatform';
import { Community } from '../../components/Community/Community';
import { SuccessStory } from '../../components/SuccessStory/SuccessStory';
import { HomeSections } from '../../global-interfaces';
import { IHomeData } from '../../../../api/public/home-api';

export function RenderSections({ data }: { data: IHomeData }) {
	const { pages: sectionsData } = data || {};
	const Components = {
		[HomeSections.courses]: Courses,
		[HomeSections.header]: Header,
		[HomeSections.whySolo]: WhySolo,
		[HomeSections.perfectPlatform]: PerfectPlatform,
		[HomeSections.community]: Community,
		[HomeSections.userStory]: SuccessStory,
		[HomeSections.pro]: ProPage,
		[HomeSections.signUp2]: SignUp2,
		[HomeSections.dreamJob]: DreamJob,
		[HomeSections.awards]: Awards,
		[HomeSections.whyCode]: WhyCode,
	};

	const setNavBarMode = (state: boolean) => {
		const SlNavigationService: any = Container.take(
			'global',
			'slNavigationService',
		);
		const service = new SlNavigationService();
		const mode = state ? SlNavbarThemes.dark : SlNavbarThemes.light;
		service.setTheme(mode);
	};

	return data ? (
		<>
			{sectionsData.map((section) => {
				const SectionComponent = Components[section.pageName];
				if (section.pageName === HomeSections.header) {
					return (
						<SectionComponent
							{...section.data}
							key={section.pageId}
							callback={setNavBarMode}
						/>
					);
				}
				return SectionComponent ? (
					<SectionComponent {...section.data} key={section.pageId} />
				) : null;
			})}
		</>
	) : null;
}
