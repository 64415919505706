import { Observable, of } from 'rxjs';

import { Container, Service } from '../../../../symphony';

import { SlHomeContext } from '../../home-context';
import { SlEventsService } from '../Business/sl-events.service';
import { IActionData } from '../../global-interfaces';
import { SlHomeTrackingService } from '../sl-home-tracking.service';

@Service()
export class SlActionsController {
	private eventsService = Container.take(SlHomeContext, SlEventsService);

	private trackingService = Container.take(
		SlHomeContext,
		SlHomeTrackingService,
	);
	// Network communication

	// Business actions

	// Mount events

	// Unmount events

	// Callback events

	public loginCallbackHandler = ({
		loginResponse,
		signUpFromModal = false,
	}: IActionData): Observable<null> => this.eventsService.loginCallback(loginResponse, {
		signUpFromModal,
	});

	// Click events

	public onStartLearningClickHandler = (): Observable<null> => this.eventsService.onStartLearningClick();

	public onAppStoreClickHandler = ({
		store,
	}: IActionData): Observable<null> => this.eventsService.onAppStoreClick(store);

	public onCloseIconClickHandler = (): Observable<null> => this.eventsService.onCloseIconClick();

	public onRecommendedCourseClickHandler = ({
		alias,
		ctaType,
	}): Observable<null> => this.eventsService.onRecommendedCourseClick(alias, ctaType);

	public onCtaClickHandler = ({
		ctaName,
		sectionType,
	}: IActionData): Observable<null> => this.eventsService.onCtaClick(ctaName, sectionType);

	// Tracking
	public onTrackActionHandler = ({
		trackData,
	}: IActionData): Observable<null> => {
		this.trackingService[trackData.actionName](trackData);
		return of(null);
	};
}
