import { Observable, of } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';

import {
	Service,
	Container,
	ILearningExperience,
} from '../../../symphony';

import { SlHomeDataService } from './sl-home-data.service';
import { SlHomeContext } from '../home-context';
import getDataJSON from '../global-constants';
import { ICourseData } from '../components/shared/CourseItem/CourseItem';
import { getInitialServerSideData } from '../../../shared/public/SlUtils/getInitialServerSideData';
import { ILearnEngineApi } from '../../../api/private/learn-engine-api/learn-engine-api.interface';
import { IHomeData } from '../../../api/public/home-api';
import { LearnEngineApi } from '../../../api/public/learn-engine/learn-engine-api';

@Service()
export class SlHomeApiService {
	private learnEngineApi: ILearnEngineApi;

	constructor() {
		this.learnEngineApi = Container.take('global', LearnEngineApi);
	}

	private dataService = Container.take(SlHomeContext, SlHomeDataService);

	private mutateHomeData = (courses: ILearningExperience[]) => {
		const homeData = getDataJSON();

		const coursePage = {
			pageId: 1,
			pageName: 'courses',
			version: 'v1',
			data: {
				courses: courses.map(
					(course): ICourseData => ({
						iconUrl: course.uiConfigurations.iconUrl,
						id: course.id,
						name: course.name,
						alias: course.alias,
						typeId: course.typeId,
						orderNumber: course.orderNumber,
						uiConfigurations: course.uiConfigurations,
					}),
				),
				description:
					'Don\'t worry, you can add as many courses as you want later.',
				note: 'People in tech are one of the most in-demand professions in the world!',
				pageTitle: 'Select your first course',
				path: 'courses',
			},
		};

		if (courses) {
			const filteredData = homeData.pages.filter(
				(item) => item.pageName === 'courses',
			);
			if (filteredData.length === 0) {
				homeData.pages.splice(1, 0, coursePage as any);
			}
			this.dataService.homeDataStream.next({ ...homeData } as IHomeData);
		} else {
			this.dataService.homeDataStream.next({ ...homeData } as IHomeData);
		}
	};

	public getHomeDataHandler(): Observable<ILearningExperience[]> {
		this.dataService.homeDataStream.next(getDataJSON() as IHomeData);
		const ssrCoursesHome = getInitialServerSideData('ssrCoursesHome');
		if (ssrCoursesHome) {
			this.mutateHomeData(ssrCoursesHome);
			return of(null);
		}
		return this.learnEngineApi.getLearningExperiences().pipe(
			tap((response) => {
				this.mutateHomeData(response);
			}),
			catchError(() => of(null)),
		);
	}
}
