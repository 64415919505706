import { BehaviorSubject, Observable } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import {
	AuthService,
	Container,
	Service,
	TrackingEventNames,
	TrackingService,
} from '../../../symphony';

import { ITrackActionData } from '../global-interfaces';

declare const slTrack: (payload: ITrackAction) => void;

declare const AF: (
	pba: 'pba',
	event: 'event',
	data: {
		eventType: string;
		eventName: string;
		eventValue?: unknown;
	}
) => void;

export interface ITrackAction {
	action: string;
	element: string;
	entityId?: number;
	redirectUrl?: string;
}

export interface IAppsFlyerAction {
	value?: Record<string, unknown>;
	name: string;
}

@Service()
export class SlHomeTrackingService {
	public trackAction = new BehaviorSubject<ITrackAction>(null);

	public appsFlyer = new BehaviorSubject<IAppsFlyerAction>(null);

	private authService = Container.take('global', AuthService);

	private fingerprint = Container.take('global', 'fingerprint');

	private newTrackingService = Container.take('global', TrackingService);

	public trackActionStream: Observable<ITrackAction> = this.trackAction.pipe(
		filter((a) => !!a),
		tap((action: ITrackAction) => {
			slTrack({
				action: action.action,
				element: action.element,
				entityId: action.entityId || null,
				redirectUrl: action.redirectUrl || null,
			});
		}),
	);

	public appsFlyerStream: Observable<IAppsFlyerAction> = this.appsFlyer.pipe(
		filter((a) => !!a),
		tap((action: IAppsFlyerAction) => {
			if (typeof AF !== 'undefined') {
				AF?.('pba', 'event', {
					eventType: 'EVENT',
					eventValue: {
						sl_platform_name: 'web',
						sl_user_id: this.authService.getUser()?.id.toString(),
						sl_locale_name: 'en',
						sl_fingerprint: this.fingerprint,
						...action.value,
					},
					eventName: action.name,
				});
			}
		}),
	);

	public onStartLearningCTAClick = (trackData: ITrackActionData): void => {
		this.newTrackingService.tracking$.next({
			eventName: TrackingEventNames.onHomeStartLearningCTAClick,
			eventParams: { ...trackData },
		});
	};

	public headerSectionView = (trackData: ITrackActionData): void => {
		this.newTrackingService.tracking$.next({
			eventName: TrackingEventNames.homeHeaderSectionView,
			eventParams: { ...trackData },
		});
	};
}
