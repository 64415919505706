import React, { memo } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';

import { LearningExperienceType } from '../../../../../symphony';

import { LocalizedLink } from '../../../../../shared/public/LocalizedLink/LocalizedLink';

import s from './CourseItemTest.scss';

export interface ICourseData {
	iconUrl: string;
	id: number;
	name: string;
	alias: string;
	typeId: LearningExperienceType;
	modules?: number;
	orderNumber: number;
	uiConfigurations: {
		isTop?: boolean;
		color?: string;
		iconUrl?: string;
		languageId?: number;
	}
}

interface ICourseItemProps {
	data: ICourseData;
	onItemClick: () => void;
}

export const CourseItem: React.FC<ICourseItemProps> = memo(
	({ data, onItemClick }) => {
		useStyles(s);
		const { name, iconUrl } = data;

		return (
			<LocalizedLink
				to={`/learn/courses/${data.alias}`}
				onClick={onItemClick}
				className="sl-home-course-item"
			>
				<div className="sl-home-course-item__image-wrapper">
					<img
						src={iconUrl}
						className="sl-home-course-item__image"
						alt="course"
						loading="lazy"
					/>
				</div>
				<p className="sl-home-course-item__name">{name}</p>
			</LocalizedLink>
		);
	},
);
