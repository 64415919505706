import { BehaviorSubject, merge, of } from 'rxjs';
import { filter, mergeMap } from 'rxjs/operators';

import { Container, history, Service } from '../../../symphony';

import { SlFeatureToggle, SlMicroFrontends } from '../../../shared/public/globalInterfaces/globalInterfaces';
import { SlHomeApiService } from './sl-home-api.service';
import { SlHomeContext } from '../home-context';
import { HomeExperiments, SlHomeActionTypes } from '../global-interfaces';
import { SlHomeTrackingService } from './sl-home-tracking.service';
import { SlHomeDataService } from './sl-home-data.service';
import { getFingerprintFromCookies } from '../utils/helperFunctions';
import { ISlLoginResponse } from '../../../login/private/global.interface';

export interface ISlHomeAction {
	type: SlHomeActionTypes;
	requestType: 'cancelable' | 'parallel' | 'sequential';
	loginResponse?: ISlLoginResponse;
}

@Service()
export class SlHomeActionsService {
	public actions = new BehaviorSubject<ISlHomeAction>(null);

	private apiHandlers = Container.take(SlHomeContext, SlHomeApiService);

	private trackAction = Container.take(SlHomeContext, SlHomeTrackingService)
		.trackAction;

	private dataService = Container.take(SlHomeContext, SlHomeDataService);

	private parallel = this.actions.pipe(
		filter((a) => a && a.requestType === 'parallel'),
		mergeMap((action) => {
			switch (action.type) {
			case SlHomeActionTypes.getHomeData:
				return this.apiHandlers.getHomeDataHandler();
			case SlHomeActionTypes.setExperiments:
				// Experiments calculation logic
				const fingerPrint: string = getFingerprintFromCookies();
				const sum = fingerPrint.split('').reduce((acc, val) => acc + val.charCodeAt(0), 0);
				const featureTogglingConfigs = Container.take(
					'global',
					'featureTogglingConfigs',
				);
				const homepageSocialSignUpExperiment = featureTogglingConfigs?.[SlMicroFrontends.mfHome]?.[
					SlFeatureToggle.homepageSocialSignUpExperiment
				]?.state;
				const mod = sum % 101;

				if (homepageSocialSignUpExperiment !== 'enabled') {
					return of(null);
				}

				if (mod > 50 && mod <= 100) {
					this.dataService.experimentsStream.next({
						...this.dataService.experimentsStream.value,
						[HomeExperiments.homepageSocialSignUp]: true,
					});
				} else {
					this.dataService.experimentsStream.next({
						...this.dataService.experimentsStream.value,
						[HomeExperiments.control]: true,
					});
					return of(null);
				}
				return of(null);
			case SlHomeActionTypes.loginCallBack:
				const isNewRegistered = action.loginResponse?.res.user.isNewRegisteredUser;
				if (isNewRegistered) {
					history.push('/onboarding');
				} else {
					history.push('/profile');
				}
				if (action.loginResponse.clickedButton) {
					const authMap = {
						googleLogin: 'welcomesignuppage_google',
						googleSignup: 'welcomesignuppage_google',
						facebookLogin: 'welcomesignuppage_facebook',
						facebookSignup: 'welcomesignuppage_facebook',
						appleLogin: 'welcomesignuppage_apple',
						appleSignup: 'welcomesignuppage_apple',
					};
					this.trackAction.next({
						action: 'login',
						element:
								authMap[
									(action.loginResponse as ISlLoginResponse)
										.clickedButton
								],
					});
				}
				return of(null);
			default:
				return of(action);
			}
		}),
	);

	public actionsStream = merge(this.parallel).pipe(filter((a) => !!a));
}
