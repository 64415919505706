import React, { memo, useRef } from 'react';

import useStyles from 'isomorphic-style-loader/useStyles';

import { SlAwards } from '../../../../shared/public/SlAwards/SlAwards';
import { SectionInView } from '../../utils/SectionInView';
import { HomeSections } from '../../global-interfaces';

import s from './Awards.scss';
import { IHomeAwardSection } from '../../../../api/public/home-api';

interface IAwardsProps extends IHomeAwardSection { }

export const Awards: React.FC<IAwardsProps> = memo(
	({ title, awards, background }) => {
		useStyles(s);
		const element = useRef<HTMLDivElement>(null);
		SectionInView(element, 'awards', HomeSections.awards);

		return (
			<section
				className="sl-home-awards"
				style={{
					background: background || '',
				}}
				ref={element}
			>
				<p className="sl-home-awards__title">{title}</p>
				<div className="sl-home-awards__awards">
					{awards.map((item, index) => (
						<SlAwards
							key={index}
							title={item.title}
							description={item.text}
							icon={item.iconId}
							iconStyle={{
								...item.iconStyle,
							}}
						/>
					))}
				</div>
			</section>
		);
	},
);
