import React, { memo, useRef, useState } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import { Button } from '@sololearnorg/sol';

import { InfoBox } from './InfoBox/InfoBox';
import { TextHighlighter } from '../shared/TextHighlighter/TextHighlighter';
import { SectionInView } from '../../utils/SectionInView';
import {
	ActionType, CtaName, HomeSections, RequestType, SectionType,
} from '../../global-interfaces';

import s from './SuccessStory.scss';
import { IHomeUserStorySection } from '../../../../api/public/home-api';
import { LocalizedLink } from '../../../../shared/public/LocalizedLink/LocalizedLink';
import { Container, i18n } from '../../../../symphony';
import { SlHomeContext } from '../../home-context';
import { SlActions } from '../../services/ActionsService/sl-actions';

interface ISuccessStoryProps extends IHomeUserStorySection {
	ctaName: CtaName
	sectionType: SectionType
}

export const SuccessStory: React.FC<ISuccessStoryProps> = memo(
	({
		stories, ctaName,
		sectionType,
	}) => {
		useStyles(s);
		const { actions$ } = Container.take(SlHomeContext, SlActions);
		const [successStory] = useState(stories[0]);

		const element = useRef<HTMLDivElement>(null);
		SectionInView(element, 'successstory', HomeSections.userStory);

		const onCtaClick = (): void => {
			actions$.next({
				type: ActionType.OnCtaClick,
				requestType: RequestType.Parallel,
				data: {
					ctaName,
					sectionType,
				},
			});
		};

		return (
			<section
				className="sl-home-story"
				sl-test-data="cmpSuccessStory"
				ref={element}
			>
				{successStory && (
					<div className="sl-home-story__container">
						<div className="sl-home-story__image">
							<img
								src={successStory.imageUrl}
								alt="success-story"
								loading="lazy"
							/>
							<InfoBox
								className="course-box"
								text={successStory.completedCourse.title}
								imageUrl={successStory.completedCourse.imageUrl}
								iconSize="medium"
							/>
							<InfoBox
								className="certificate-box"
								iconId={successStory.certificate.icon}
								text={successStory.certificate.title}
								iconSize="large"
							/>
							<InfoBox
								className="quotes-box"
								iconId="doubleQuotes"
								iconSize="small"
							/>
						</div>
						<div className="sl-home-story__title">
							<TextHighlighter
								fullStr={successStory.title}
								highlightedStr={successStory.titleHighlighted}
								styles={{
									color: '#2493DF',
									'font-family': 'Caveat, Gloria Hallelujah',
									'font-weight': 'normal',
								}}
							/>
						</div>
						<div className="sl-home-story__info">
							{successStory.description}
						</div>
						<hr className="sl-home-story__hr" />
						<div className="sl-home-story__cta">
							<Button
								buttonType="primary"
								asChild
								onClick={onCtaClick}
							>
								<LocalizedLink to="/users/signup">
									{i18n.t('web-home.success-story-cta')}
								</LocalizedLink>
							</Button>
						</div>
					</div>
				)}
			</section>
		);
	},
);
