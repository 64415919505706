import { AppStore } from '../../shared/public/globalInterfaces/globalInterfaces';
import { ISlLoginResponse } from '../../login/private/global.interface';
import { IHomeHeaderSection } from '../../api/public/home-api';

export enum SlHomeActionTypes {
	getHomeData = 'getHomeData',

	onHeaderActionButtonClick = 'onHeaderStartLearningNow',
	onWhyCodeActionButtonClick = 'onWhyCodeActionButtonClick',
	onSignUp2ActionButtonClick = 'onSignUp2ActionButtonClick',
	secondaryStartLearningNow = 'secondaryStartLearningNow',
	loginCallBack = 'loginCallBack',
	setExperiments = 'setExperiments',
}

export interface IAction {
	type: ActionType;
	requestType?: RequestType;
	data?: IActionData;
}

export enum ActionType {
	// Network communication

	// Business actions

	// Mount events

	// Unmount events

	// Callback events
	LoginCallback,

	// Click events
	OnStartLearningClick,
	OnSignUpSecondClick,
	OnAppStoreClick,
	OnCloseIconClick,
	OnRecommendedCourseClick,
	OnCtaClick,

	// Tracking
	Track,
}

export enum RequestType {
	Parallel,
	Cancelable,
	Sequential,
}

export interface IActionData {
	store?: AppStore;
	loginResponse?: ISlLoginResponse;
	signUpFromModal?: boolean;
	alias?: string;
	ctaType?: CoursePageClickCTAType;
	ctaName?: CtaName;
	sectionType?: SectionType;
	trackData?: ITrackActionData;
}

export enum HomeSections {
	header = 'header',
	whySolo = 'why_sololearn',
	perfectPlatform = 'perfect_platform',
	community = 'community',
	userStory = 'user_story',
	pro = 'pro',
	signUp2 = 'sign_up_2',
	awards = 'awards',
	dreamJob = 'dream_job',
	courses = 'courses',
	whyCode = 'why_code',
}

export interface ISectionsDisabledActions {
	header?: boolean;
	whySolo?: boolean;
	perfectPlatform?: boolean;
	community?: boolean;
	userStory?: boolean;
	pro?: boolean;
	signUp2?: boolean;
	awards?: boolean;
	dreamJob?: boolean;
	courses?: boolean;
	whyCode?: boolean;
	footer?: boolean;
}

export enum SlHomeModalIds {
	loginModal = 'homeLoginModal',
}

export enum HomeExperiments {
	homepageSocialSignUp = 'homepage_social_signup',
	control = 'control',
}

export enum AppsFlyerEventNames {
	slHomePageView = 'sl_home_page_view',
}

export interface IHeaderProps extends IHomeHeaderSection {
	callback: (arg?: boolean) => void;
	ctaName: CtaName;
	sectionType: SectionType;
}

export enum TrackActionName {
	OnStartLearningCTAClick = 'onStartLearningCTAClick',
	HeaderSectionView = 'headerSectionView',
}

export interface ITrackActionData {
	actionName: TrackActionName;
	aFWebUserId?: string;
	experienceTypeId?: string;
	experienceAlias?: string;
	ctaType?: string;
	sectionType?: string;
	sectionTitle?: string;
	urlText?: string;
	version?: string;
}

export enum CoursePageClickPageAlias {
	HomePage = 'HOMEPAGE',
	Discuss = 'DISCUSS',
	Catalog = 'CATALOG',
}

export enum CoursePageClickCTAType {
	Course = 'COURSE',
	AllCourses = 'ALL_COURSES',
}

export enum CtaName {
	START_FROM_SCRATCH = 'START FROM SCRATCH',
	DO_QUICK_LESSON = 'DO A QUICK LESSON',
	BECOME_CERTIFIED = 'BECOME CERTIFIED',
	EXPLORE_FULL_CATALOG = 'EXPLORE FULL CATALOG',
	LEARN_FOR_FREE = 'LEARN FOR FREE',
	BOOST_YOUR_CAREER = 'BOOST YOUR CAREER',
	JOIN_TODAY = 'JOIN TODAY',
	I_WANT_TO_CODE = 'I WANT TO CODE',
	START_LEARNING = 'START LEARNING',
	START_LEARNING_LIKE_A_PRO = 'START LEARNING LIKE A PRO',
	START_YOUR_SUCCESS_STORY = 'START YOUR SUCCESS STORY',
	SEE_MORE_OPTIONS = 'SEE MORE OPTIONS',
	UNDEFINED = 'UNDEFINED',
}

export enum SectionType {
	MAIN_HEADER_PAGE = 'MAIN HEADER PAGE',
	CUSTOM = 'CUSTOM',
	CONTACT_FORM = 'CONTACT FORM',
	USP = 'USP',
	MOBILE_APPS_COURSES = 'MOBILE APPS & COURSES',
	STUDENTS_VS_PROFESSIONALS = 'STUDENTS VS PROFESSIONALS',
	COMMUNITY = 'COMMUNITY',
	WHY_CODE = 'WHY CODE?',
	TESTIMONIAL = 'TESTIMONIAL',
	UNDEFINED = 'UNDEFINED',
}
