import React, { useRef } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';

import { PerfectPlatformItem } from './PerfectPlatformItem';
import { SectionInView } from '../../utils/SectionInView';
import { CtaName, HomeSections, SectionType } from '../../global-interfaces';

import s from './PerfectPlatform.scss';
import { IHomePerfectPlatformSection } from '../../../../api/public/home-api';

export interface IPerfectPlatformProps extends IHomePerfectPlatformSection {
	platform: (IHomePerfectPlatformSection['platform'][0] & {
		ctaName: CtaName,
		sectionType: SectionType
	})[]
}

export function PerfectPlatform({
	title,
	platform,
}: IPerfectPlatformProps): JSX.Element {
	useStyles(s);
	const element = useRef<HTMLDivElement>(null);

	SectionInView(element, 'perfectplatform', HomeSections.perfectPlatform);

	return (
		<section className="sl-home-perfect-platform" ref={element}>
			<h2 className="sl-home-perfect-platform__title">{title}</h2>
			<div className="sl-home-perfect-platform__items-wrapper">
				{platform.map((item) => (
					<PerfectPlatformItem
						key={item.name}
						name={item.name}
						description={item.description}
						cta={item.cta}
						icon={item.icon}
						ctaName={item.ctaName}
						sectionType={item.sectionType}
					/>
				))}
			</div>
		</section>
	);
}
