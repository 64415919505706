import React from 'react';
// import { getExperimentWithoutExposing, prefetchExperimentData } from '../../../../shared/public/experimentsHandler/experimentsHandler';
// import { CoursesControl } from './CoursesControl';
// import { webHomeFirstScreenExpKey } from '../../global-constants';
import { CoursesTest } from '../CoursesTest/CoursesTest';
import { ICourseData } from '../shared/CourseItem/CourseItem';

export interface ICoursesProps {
	courses: ICourseData[];
}

export const Courses = (props: ICoursesProps) => <CoursesTest {...props} />;
// {
// const [homeFirstScreenExpData, setHomeFirstScreenExpData] = useState(null);

// const getExperimentData = async () => {
// 	await prefetchExperimentData(webHomeFirstScreenExpKey);
// 	setHomeFirstScreenExpData(getExperimentWithoutExposing(webHomeFirstScreenExpKey));
// };

// useEffect(() => {
// 	getExperimentData();
// }, []);

// if (!homeFirstScreenExpData) {
// 	return null;
// }

// if (homeFirstScreenExpData.key === 'control') {
// 	return <CoursesControl {...props} />;
// }

// return  <CoursesTest {...props} />;
// }
