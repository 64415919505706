import {
	RefObject, useEffect, useRef, useState,
} from 'react';

export function useIsVisibleHook(
	ref: RefObject<HTMLElement>,
	threshold?: number,
	defaultState?: boolean,
	rootMargin?: string,
): boolean {
	const [isOnScreen, setIsOnScreen] = useState(defaultState || false);
	const observerRef = useRef(null);

	useEffect(() => {
		observerRef.current = new IntersectionObserver(
			([entry]) => setIsOnScreen(entry.isIntersecting),
			{
				root: null,
				rootMargin: rootMargin || '0px',
				threshold: threshold || 0,
			},
		);
	}, []);

	useEffect(() => {
		observerRef.current.observe(ref?.current);

		return () => {
			observerRef.current.disconnect();
		};
	}, [ref]);

	return isOnScreen;
}
