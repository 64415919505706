import React, { memo } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';

import { SlIcon, backLeft, useIcons } from '@sololearnorg/icons';

import {
	ISlHorizontalScrollCommand,
	ISlHorizontalScrollState,
} from '../SlHorizontalScrollList/SlHorizontalScrollList';

import s from './SlSliderArrows.scss';

interface ISlSliderArrowsProps {
    cssClass?: string;
    isShown?: boolean;
    scrollState: ISlHorizontalScrollState;
    directionHandler: (data: ISlHorizontalScrollCommand) => void;
}

export const SlSliderArrows: React.FC<ISlSliderArrowsProps> = memo(
	({
		cssClass, scrollState, directionHandler, isShown,
	}) => {
		useStyles(s);
		useIcons([backLeft]);

		if (!isShown) {
			return null;
		}
		return (
			<div
				className={`sl-slider-arrows${cssClass ? ` ${cssClass}` : ''}`}
				sl-test-data="cmpSlSliderArrows"
			>
				<button
					className={`sl-slider-arrows__arrow sl-slider-arrows__arrow--previous${!scrollState.hasLeftScroll
						? ' sl-slider-arrows__arrow--disable'
						: ''
					}`}
					onClick={() => directionHandler({ direction: 'left' })}
					type="button"
				>
					<SlIcon iconId="backLeft" />
				</button>

				<button
					className={`sl-slider-arrows__arrow sl-slider-arrows__arrow--next${!scrollState.hasRightScroll
						? ' sl-slider-arrows__arrow--disable'
						: ''
					}`}
					onClick={() => directionHandler({ direction: 'right' })}
					type="button"
				>
					<SlIcon iconId="backLeft" />
				</button>
			</div>
		);
	},
);
