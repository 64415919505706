import React, { memo, useRef } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import { HomeSections } from '../../global-interfaces';
import { SectionInView } from '../../utils/SectionInView';
import { GridCard, IGridCardProps } from '../shared/GridCard/GridCard';

import s from './WhySolo.scss';

interface IWhySoloProps {
	benefits?: IGridCardProps[];
}

export const WhySolo: React.FC<IWhySoloProps> = memo(({ benefits }) => {
	useStyles(s);
	const element = useRef<HTMLDivElement>(null);
	SectionInView(element, 'whysololearn', HomeSections.whySolo);

	return (
		<section className="sl-home-why-solo" ref={element}>
			<div className="sl-home-why-solo__grid-container">
				{benefits.map((el, i) => (
					<GridCard
						size={el.size}
						title={el.title}
						description={el.description}
						highlightedText={el.highlightedText}
						cta={el.cta}
						icon={el.icon}
						ctaType={el.ctaType}
						ctaName={el.ctaName}
						ctaUrl={el.ctaUrl}
						sectionType={el.sectionType}
						key={i}
					/>
				))}
			</div>
		</section>
	);
});
