import React, { useRef } from 'react';
import { useLocation } from 'react-router-dom';
import useStyles from 'isomorphic-style-loader/useStyles';

import {
	check, proGold, SlIcon, useIcons,
} from '@sololearnorg/icons';
import { Button } from '@sololearnorg/sol';
import { Container, history } from '../../../../symphony';

import { SlFeatureToggle, SlMicroFrontends } from '../../../../shared/public/globalInterfaces/globalInterfaces';
import { SlHomeContext } from '../../home-context';
import { TextHighlighter } from '../shared/TextHighlighter/TextHighlighter';
import { SectionInView } from '../../utils/SectionInView';
import { SlActions } from '../../services/ActionsService/sl-actions';
import {
	ActionType,
	HomeSections,
	RequestType,
	TrackActionName,
} from '../../global-interfaces';

import s from './ProPage.scss';
import { IHomeProSection } from '../../../../api/public/home-api';

interface IProPageProps extends IHomeProSection { }

export const ProPage: React.FC<IProPageProps> = React.memo(
	({
		title, proIconId, packages, offersRow, actionText,
	}) => {
		useStyles(s);
		useIcons([proGold, check]);

		const element = useRef<HTMLDivElement>(null);
		SectionInView(element, 'pro', HomeSections.pro);

		const pathName = useLocation().pathname.replace(/^\//, ''); // cut last /

		const goTopro = () => {
			const { actions$ } = Container.take(SlHomeContext, SlActions);

			const featureTogglingConfigs = Container.take(
				'global',
				'featureTogglingConfigs',
			);

			actions$.next({
				type: ActionType.Track,
				requestType: RequestType.Parallel,
				data: {
					trackData: {
						actionName: TrackActionName.OnStartLearningCTAClick,
						experienceTypeId: '4',
						experienceAlias: '/home',
						ctaType: 'START LEARNING LIKE A PRO',
						sectionType: 'PRO PAGE',
						sectionTitle: 'pro',
					},
				},
			});

			const nonLoggedInToggle = featureTogglingConfigs?.[SlMicroFrontends.mfHome]?.[
				SlFeatureToggle.nonLoggedInProPage
			]?.state;

			const defineReturnUrl = () => {
				if (nonLoggedInToggle === 'enabled') {
					return '';
				}

				return `&returnUrl=/${pathName}`;
			};

			history.push(
				`/payments/pro?source=landingpage${defineReturnUrl()}`,
			);
		};

		return (
			<section className="sl-home-propage" ref={element}>
				<div className="sl-home-propage__wrapper">
					<div className="sl-home-propage__wrapper__heading">
						{title}
						<div className="sl-home-propage__wrapper__heading__icon">
							<SlIcon iconId={proIconId} />
						</div>
					</div>
					<div className="sl-home-propage__wrapper__cont">
						{packages.map((item) => (
							<div
								key={item.benefit}
								className="sl-home-propage__wrapper__cont__item"
							>
								<p className="sl-home-propage__wrapper__cont__item__title">
									{item.title}
								</p>
								<p className="sl-home-propage__wrapper__cont__item__discount">
									{item.benefit}
								</p>
								<div className="sl-home-propage__wrapper__cont__item__offer">
									<TextHighlighter
										fullStr={item.description}
										highlightedStr={item.highlightedText}
										styles={{ color: '#fbb461' }}
									/>
								</div>
							</div>
						))}
					</div>
					<div className="sl-home-propage__wrapper__benefits">
						<div className="sl-home-propage__wrapper__benefits__row">
							{offersRow.map((item) => (
								<div
									key={item.offer}
									className="sl-home-propage__wrapper__benefits__row-item"
								>
									<div className="sl-home-propage__wrapper__benefits__row-item__icon">
										<SlIcon iconId={item.iconId} />
									</div>
									<p>{item.offer}</p>
								</div>
							))}
						</div>
					</div>
					<div className="sl-home-propage__wrapper__button">
						<Button buttonType="pro" onClick={goTopro}>
							{actionText}
						</Button>
					</div>
				</div>
			</section>
		);
	},
);
