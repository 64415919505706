import React from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';

import s from './WhyCode.scss';

export interface IWhyCodeItemProps {
	title: string;
	description: string;
	icon: string;
}

export function WhyCodeItem({
	title,
	description,
	icon,
}: IWhyCodeItemProps): JSX.Element {
	useStyles(s);

	return (
		<div
			className="sl-home-why-code-benefit-item"
			sl-test-data="cmpWhyCodeItem"
		>
			<div className="sl-home-why-code-benefit-item__icon-wrapper">
				<img
					className="sl-home-why-code-benefit-item__icon"
					src={icon}
					alt="why-code-icon"
					loading="lazy"
				/>
			</div>
			<div className="sl-home-why-code-benefit-item__texts">
				<div className="sl-home-why-code-benefit-item__text-wrapper">
					<p className="sl-home-why-code-benefit-item__title">
						{title}
					</p>
					<div
						dangerouslySetInnerHTML={{
							__html: description,
						}}
						className="sl-home-why-code-benefit-item__description"
					/>
				</div>
			</div>
		</div>
	);
}
