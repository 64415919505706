import { Observable, of } from 'rxjs';

import {
	Container,
	Service,
	history,
	TrackingService,
	TrackingEventNames,
} from '../../../../symphony';

import { AppStore } from '../../../../shared/public/globalInterfaces/globalInterfaces';
import { ISlLoginResponse } from '../../../../login/private/global.interface';
import { SlHomeContext } from '../../home-context';
import { SlHomeDataService } from '../sl-home-data.service';
import { SlActions } from '../ActionsService/sl-actions';
import {
	ActionType,
	CoursePageClickCTAType,
	CoursePageClickPageAlias,
	CtaName,
	RequestType,
	TrackActionName,
} from '../../global-interfaces';

@Service()
export class SlEventsService {
	private tracking$ = Container.take('global', TrackingService).tracking$;

	private actions$ = Container.take(SlHomeContext, SlActions).actions$;

	private dataService = Container.take(SlHomeContext, SlHomeDataService);

	public onStartLearningClick = (): Observable<null> => {
		this.tracking$.next({
			eventName: TrackingEventNames.clickToSignUp,
			eventParams: {
				signupCta: 'start_learning_now_header',
			},
		});

		this.actions$.next({
			type: ActionType.Track,
			requestType: RequestType.Parallel,
			data: {
				trackData: {
					actionName: TrackActionName.OnStartLearningCTAClick,
					experienceTypeId: '4',
					experienceAlias: '/home',
					ctaType: 'START LEARNING NOW',
					sectionType: 'MAIN HEADER PAGE',
					sectionTitle: 'header',
				},
			},
		});

		history.push('/users/signup');

		return of(null);
	};

	public onAppStoreClick = (store: AppStore): Observable<null> => {
		this.tracking$.next({
			eventName: TrackingEventNames.appStoreClick,
			eventParams: {
				source: 'home_mobile_promo',
				store,
			},
		});
		return of(null);
	};

	public onRecommendedCourseClick = (
		alias: string,
		ctaType: CoursePageClickCTAType,
	): Observable<null> => {
		this.tracking$.next({
			eventName: TrackingEventNames.onCourseClick,
			eventParams: {
				pageAlias: CoursePageClickPageAlias.HomePage,
				materialId: '',
				ctaType,
				experienceAlias: alias,
			},
		});

		return of(null);
	};

	public onCloseIconClick = (): Observable<null> => {
		this.dataService.isShownSignUpModal$.next(false);
		return of(null);
	};

	public onCtaClick = (ctaName: CtaName, sectionType): Observable<null> => {
		this.tracking$.next({
			eventName: TrackingEventNames.onHomeStartLearningCTAClick,
			eventParams: {
				experienceAlias: '/home',
				experienceTypeId: '4',
				ctaType: ctaName,
				sectionType,
				sectionTitle: '',
				clickType: 'CTA',
				version: '2-0-0',
			},
		});

		return of(null);
	};

	public loginCallback = (
		loginResponse: ISlLoginResponse,
		options: {
			signUpFromModal?: boolean;
		} = {},
	): Observable<null> => {
		if (loginResponse) {
			if (options.signUpFromModal) {
				this.dataService.isShownSignUpModal$.next(false);
			}
			const isNewRegistered = loginResponse.res.user.isNewRegisteredUser;
			if (isNewRegistered) {
				history.push('/onboarding');
			} else {
				history.push('/profile');
			}
		}

		return null;
	};
}
