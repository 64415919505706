import { merge, of, Subject } from 'rxjs';
import { filter, mergeMap, takeUntil } from 'rxjs/operators';

import { Container, Service } from '../../../../symphony';

import { SlActions } from './sl-actions';
import { SlActionsController } from './sl-actions.controller';
import { SlHomeContext } from '../../home-context';
import { ActionType, IAction, RequestType } from '../../global-interfaces';

@Service()
export class SlActionsService {
	private actions$ = Container.take(SlHomeContext, SlActions).actions$;

	private controller = Container.take(SlHomeContext, SlActionsController);

	// private cancelableActionsMap = {
	//     // Network communication
	//     // Business actions
	// };

	private parallelActionsMap = {
		// Network communication
		// Business actions

		// Mount events
		// Unmount events
		// Callback events
		[ActionType.LoginCallback]: this.controller.loginCallbackHandler,

		// Click events
		[ActionType.OnStartLearningClick]:
            this.controller.onStartLearningClickHandler,
		[ActionType.OnAppStoreClick]: this.controller.onAppStoreClickHandler,
		[ActionType.OnCloseIconClick]: this.controller.onCloseIconClickHandler,
		[ActionType.OnRecommendedCourseClick]:
            this.controller.onRecommendedCourseClickHandler,
		[ActionType.OnCtaClick]:
            this.controller.onCtaClickHandler,

		// Tracking
		[ActionType.Track]: this.controller.onTrackActionHandler,
	};

	constructor() {
		const unsubscriber: Subject<void> = Container.take(
			SlHomeContext,
			'unsubscriber',
		);
		// const cancelable = this.actions$.pipe(
		//     filter((a) => !!a && a.requestType === RequestType.Cancelable),
		//     switchMap((action: IAction) => {
		//         const handler = this.cancelableActionsMap[action.type];
		//         return handler ? handler(action.data) : of(action);
		//     })
		// );

		const parallel = this.actions$.pipe(
			filter((a: any) => !!a && a.requestType === RequestType.Parallel),
			mergeMap((action: IAction) => {
				const handler = this.parallelActionsMap[action.type];
				return handler ? handler(action.data) : of(action);
			}),
		);

		merge(parallel)
			.pipe(
				filter((a) => !!a),
				takeUntil(unsubscriber),
			)
			.subscribe();
	}
}
