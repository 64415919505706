import React from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import { Button } from '@sololearnorg/sol';

import s from './PerfectPlatform.scss';
import { LocalizedLink } from '../../../../shared/public/LocalizedLink/LocalizedLink';
import { Container } from '../../../../symphony';
import { SlHomeContext } from '../../home-context';
import { SlActions } from '../../services/ActionsService/sl-actions';
import {
	ActionType, CtaName, RequestType, SectionType,
} from '../../global-interfaces';

interface IPerfectPlatformItemProps {
	name: string;
	description: string;
	icon: string;
	cta: string;
	ctaName: CtaName;
	sectionType: SectionType;
}

export function PerfectPlatformItem({
	name,
	description,
	icon,
	cta,
	ctaName,
	sectionType,
}: IPerfectPlatformItemProps): JSX.Element {
	const { actions$ } = Container.take(SlHomeContext, SlActions);
	useStyles(s);

	const ctaClickHandler = () => {
		actions$.next({
			type: ActionType.OnCtaClick,
			requestType: RequestType.Parallel,
			data: {
				ctaName,
				sectionType,
			},
		});
	};

	return (
		<div className="sl-home-perfect-platform-item">
			<img
				className="sl-home-perfect-platform-item__icon"
				src={icon}
				alt="sl-home-perfect-img"
				loading="lazy"
			/>
			<div className="sl-home-perfect-platform-item__name">{name}</div>
			<span className="sl-home-perfect-platform-item__description">
				{description}
			</span>
			<Button
				buttonType="primary"
				asChild
				className="sl-home-perfect-platform-item__cta"
				onClick={() => { ctaClickHandler(); }}
			>
				<LocalizedLink to="/users/signup">
					{cta}
				</LocalizedLink>
			</Button>
		</div>
	);
}
