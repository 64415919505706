import React from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';

import { AvatarCircle } from '../../shared/AvatarCircle/AvatarCircle';

import s from './Bubbles.scss';

interface IBubbleProps {
	avatars: string[];
}

export function Bubbles({ avatars }: IBubbleProps): JSX.Element {
	useStyles(s);

	return (
		<div className="sl-home-bubbles">
			<div className="sl-home-bubbles--left">
				<div>
					<AvatarCircle size={56} />
				</div>
				<div>
					<AvatarCircle size={92} imgUrl={avatars[0]} />
					<AvatarCircle size={66} imgUrl={avatars[1]} />
				</div>
				<div>
					<AvatarCircle size={24} />
					<AvatarCircle size={92} imgUrl={avatars[2]} />
				</div>

				<div>
					<AvatarCircle size={156} imgUrl={avatars[3]} />
				</div>
			</div>
			<div className="sl-home-bubbles--right">
				<div>
					<AvatarCircle size={28} />
				</div>
				<div>
					<AvatarCircle size={92} imgUrl={avatars[4]} />
					<AvatarCircle size={66} imgUrl={avatars[5]} />
				</div>
				<div>
					<AvatarCircle size={28} />
					<AvatarCircle size={156} imgUrl={avatars[6]} />
				</div>
				<div>
					<AvatarCircle size={92} imgUrl={avatars[7]} />
				</div>
			</div>

			<div className="sl-home-bubbles--mobile">
				<div>
					<AvatarCircle size={61} imgUrl={avatars[0]} />

					<AvatarCircle size={24} />
					<AvatarCircle size={39} imgUrl={avatars[1]} />

					<AvatarCircle size={54} imgUrl={avatars[2]} />
				</div>
				<div>
					<AvatarCircle size={8} />

					<AvatarCircle size={32} imgUrl={avatars[3]} />

					<AvatarCircle size={32} imgUrl={avatars[4]} />
					<AvatarCircle size={32} imgUrl={avatars[5]} />
				</div>
				<div>
					<AvatarCircle size={32} imgUrl={avatars[6]} />

					<AvatarCircle size={60} imgUrl={avatars[7]} />

					<AvatarCircle size={16} />
				</div>
			</div>
		</div>
	);
}
