import React, { useEffect } from 'react';

import { AuthService, Container } from '../../../features/symphony';

import { SlNavBarPositions, SlNavbarThemes } from '../../../features/shared/public/globalInterfaces/globalInterfaces';
import { SlHome } from '../../../features/home/public/SlHome/SlHome';
import { SlNavbarState } from '../../../features/navigation/private/global-interface';
import { setNavigationConfigs } from '../../utils/navigationManager';

export const Home = React.memo(() => {
	useEffect(() => {
		const authService = Container.take('global', AuthService);

		if (!authService.isLoggedIn()) {
			setNavigationConfigs({
				state: SlNavbarState.shown,
				theme: SlNavbarThemes.dark,
				position: SlNavBarPositions.sticky,
			});
		}
		return () => {
			setNavigationConfigs({
				state: SlNavbarState.hidden,
			});
		};
	}, []);

	return <SlHome />;
});
