import React from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
// import { exposeExperiment, getExperimentWithoutExposing, prefetchExperimentData } from '../../../../shared/public/experimentsHandler/experimentsHandler';
// import { HeaderControl } from './HeaderControl';
import { IHeaderProps } from '../../global-interfaces';
import { HeaderTest } from '../HeaderTest/HeaderTest';
// import { webHomeFirstScreenExpKey } from '../../global-constants';
import s from './Header.scss';

export const Header = (props: IHeaderProps) => {
	useStyles(s);
	// const [homeFirstScreenExpData, setHomeFirstScreenExpData] = useState(null);

	// const getExperimentData = async () => {
	// 	await prefetchExperimentData(webHomeFirstScreenExpKey);
	// 	setHomeFirstScreenExpData(getExperimentWithoutExposing(webHomeFirstScreenExpKey));
	// 	exposeExperiment(webHomeFirstScreenExpKey);
	// };

	// useEffect(() => {
	// 	getExperimentData();
	// }, []);

	// if (!homeFirstScreenExpData) {
	// 	return (
	// 		<section
	// 			className="sl-home-header"
	// 		/>
	// 	);
	// }

	// if (homeFirstScreenExpData.key === 'control') {
	// 	return <HeaderControl {...props} />;
	// }

	return <HeaderTest {...props} />;
};
