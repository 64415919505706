import React, { useEffect, useState } from 'react';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import useStyles from 'isomorphic-style-loader/useStyles';

import { Modal } from '@sololearnorg/sol';
import { Container } from '../../../../../symphony';

import { SlActions } from '../../../services/ActionsService/sl-actions';
import { SlHomeContext } from '../../../home-context';
import { SlHomeDataService } from '../../../services/sl-home-data.service';
import { ActionType, RequestType } from '../../../global-interfaces';

import s from './Auth.scss';
import { ISlLoginResponse } from '../../../../../login/private/global.interface';
import { SlLogin } from '../../../../../login/public/SlLogin/SlLogin';

export function Auth(): JSX.Element {
	useStyles(s);
	const [showModal, setShowModal] = useState<boolean>(false);
	const { actions$ } = Container.take(SlHomeContext, SlActions);

	useEffect(() => {
		const unsubscriber = new Subject<void>();

		const { isShownSignUpModal$ } = Container.take(
			SlHomeContext,
			SlHomeDataService,
		);

		isShownSignUpModal$
			.pipe(takeUntil(unsubscriber))
			.subscribe(setShowModal);

		return () => {
			unsubscriber.next();
			unsubscriber.complete();
		};
	}, []);

	const onCloseIconClick = (): void => {
		actions$.next({
			type: ActionType.OnCloseIconClick,
			requestType: RequestType.Parallel,
		});
	};

	const loginCallback = (loginResponse: ISlLoginResponse): void => {
		actions$.next({
			type: ActionType.LoginCallback,
			requestType: RequestType.Parallel,
			data: {
				loginResponse,
				signUpFromModal: true,
			},
		});
	};

	return (
		showModal && (
			<Modal.Custom onClose={onCloseIconClick} dataTestid="cmpAuthModal">
				<SlLogin
					loginCallback={loginCallback}
					configs={{
						useMock: false,
						disableScrollToTop: true,
						isTitleH1: false,
					}}
					defaultRoute="signup"
					useRouter={false}
					cssClass="home-login"
					trackingPrefix="home"
				/>
			</Modal.Custom>
		)
	);
}
