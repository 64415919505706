import React, {
	memo, Suspense, useEffect, useRef, useState,
} from 'react';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import useStyles from 'isomorphic-style-loader/useStyles';

import {
	appStoreBadge,
	backLeft,
	certificateV2Complete,
	doubleQuotes,
	heart,
	homeSparkleV1,
	homeSparkleV2,
	kotlin,
	playStoreBadge,
	useIcons,
} from '@sololearnorg/icons';
import { AuthService, Container, i18n } from '../../../../symphony';

import { SlHomeContext } from '../../home-context';
import { SlHomeActionsService } from '../../services/sl-home-actions.service';
import {
	ActionType,
	AppsFlyerEventNames,
	RequestType,
	SlHomeActionTypes,
	TrackActionName,
} from '../../global-interfaces';
import { SlHomeDataService } from '../../services/sl-home-data.service';
import { SectionInView } from '../../utils/SectionInView';
import { SlHomeTrackingService } from '../../services/sl-home-tracking.service';
import { getFingerprintFromCookies } from '../../utils/helperFunctions';
import { ModalContainer } from '../ModalContainer/ModalContainer';
import { SlActions } from '../../services/ActionsService/sl-actions';
import { RenderSections } from './RenderSections';
import { SlFooter } from '../../../../navigation/public/SlFooter/SlFooter';
import { useComponentWillMount } from '../../../../shared/public/SlUtils/useComponentWillMount';
import { SlHelmet } from '../../../../shared/public/SlHelmet/SlHelmet';

import s from './HomeContainer.scss';
import { IHomeData } from '../../../../api/public/home-api';

interface IHomeContainerProps { }

export const HomeContainer: React.FC<IHomeContainerProps> = memo(() => {
	useStyles(s);
	const element = useRef<HTMLDivElement>(null);
	SectionInView(element, 'footer', 'footer');

	const { actions } = Container.take(SlHomeContext, SlHomeActionsService);

	const { homeDataStream } = Container.take(SlHomeContext, SlHomeDataService);

	useIcons([
		appStoreBadge,
		playStoreBadge,
		heart,
		doubleQuotes,
		backLeft,
		kotlin,
		certificateV2Complete,
		homeSparkleV1,
		homeSparkleV2,
	]);

	useComponentWillMount(() => {
		actions.next({
			type: SlHomeActionTypes.getHomeData,
			requestType: 'parallel',
		});
	});

	const [homeData, setHomeData] = useState<IHomeData>(
		Container.take(SlHomeContext, SlHomeDataService).homeDataStream.value,
	);

	useEffect(() => {
		const unsubscriber = new Subject<void>();
		const { appsFlyer } = Container.take(
			SlHomeContext,
			SlHomeTrackingService,
		);

		homeDataStream.pipe(takeUntil(unsubscriber)).subscribe(setHomeData);

		const callBack = () => {
			actions.next({
				type: SlHomeActionTypes.setExperiments,
				requestType: 'parallel',
			});
		};
		if (getFingerprintFromCookies()) {
			actions.next({
				type: SlHomeActionTypes.setExperiments,
				requestType: 'parallel',
			});
		} else {
			document.addEventListener('fngprntDefined', callBack);
		}

		appsFlyer.next({
			name: AppsFlyerEventNames.slHomePageView,
		});

		return () => {
			document.removeEventListener('fngprntDefined', callBack);
			unsubscriber.next();
			unsubscriber.complete();
		};
	}, []);

	useEffect(() => {
		const authService = Container.take('global', AuthService);
		if (!!location.pathname.split('/')[2] || authService.isLoggedIn()) return; // If not homepage, already redirected to another page

		const { actions$ } = Container.take(SlHomeContext, SlActions);
		actions$.next({
			type: ActionType.Track,
			requestType: RequestType.Parallel,
			data: {
				trackData: {
					actionName: TrackActionName.HeaderSectionView,
					experienceTypeId: '4',
					experienceAlias: '/home',
					sectionType: 'MAIN HEADER PAGE',
					sectionTitle: 'header',
					urlText: location.href,
					version: '2-0-0',
				},
			},
		});
	}, []);

	return (
		<>
			<SlHelmet
				title={i18n.t('web-home.meta-title')}
				description={i18n.t('web-home.meta-description')}
			/>
			<div sl-test-data="cmpHomeContainer" className="sl-home-container">
				{typeof window !== 'undefined' ? (
					<Suspense fallback={null}>
						<RenderSections data={homeData} />
					</Suspense>
				) : (
					<RenderSections data={homeData} />
				)}
				<div ref={element}>
					<SlFooter />
				</div>
			</div>
			<ModalContainer />
		</>
	);
});
