import React, { useRef } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';

import { Button } from '@sololearnorg/sol';
import { Container } from '../../../../symphony';

import { WhyCodeItem } from './WhyCodeItem';
import { SlHomeContext } from '../../home-context';
import { SectionInView } from '../../utils/SectionInView';
import {
	ActionType, CtaName, HomeSections, RequestType, SectionType,
} from '../../global-interfaces';
import { SlActions } from '../../services/ActionsService/sl-actions';
import s from './WhyCode.scss';
import { IHomeWhyCodeSection } from '../../../../api/public/home-api';
import { LocalizedLink } from '../../../../shared/public/LocalizedLink/LocalizedLink';

interface IWhyCodeProps extends IHomeWhyCodeSection {
	ctaName: CtaName
	sectionType: SectionType
	cta: string
}

export function WhyCode({
	title,
	titleHighlighted,
	benefits,
	ctaName,
	sectionType,
	cta,
}: IWhyCodeProps): JSX.Element {
	useStyles(s);
	const { actions$ } = Container.take(SlHomeContext, SlActions);
	const element = useRef<HTMLDivElement>(null);
	SectionInView(element, 'whycode', HomeSections.whyCode);

	const onWhyCodeActionClick = (): void => {
		actions$.next({
			type: ActionType.OnCtaClick,
			requestType: RequestType.Parallel,
			data: {
				ctaName,
				sectionType,
			},
		});
	};

	return (
		<section
			className="sl-home-why-code"
			ref={element}
			sl-test-data="cmpWhyCode"
		>
			<div className="sl-home-why-code__content">
				<h2 className="sl-home-why-code__title">
					{title}
					<span className="sl-home-why-code__highlighted-title-wrapper">
						<p className="sl-home-why-code__highlighted-title-wrapper__text">
							{titleHighlighted}
						</p>
						<span className="sl-home-why-code__line" />
					</span>
				</h2>
				<div className="sl-home-why-code__benefits">
					{benefits.map((item, index) => (
						<WhyCodeItem
							key={index}
							title={item.title}
							description={item.description}
							icon={item.icon}
						/>
					))}
				</div>
				<div className="sl-home-why-code__action-btn-wrapper">
					<Button
						onClick={onWhyCodeActionClick}
						display="full"
						className="sl-home-why-code__action-btn-wrapper__btn"
						dataTestid="btnWhyCode"
						asChild
					>
						<LocalizedLink to="/users/signup">
							{cta}
						</LocalizedLink>
					</Button>
				</div>
			</div>
		</section>
	);
}
