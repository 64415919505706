import React, { useEffect, useRef } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';

import { SlIcon } from '@sololearnorg/icons';
import { Button } from '@sololearnorg/sol';
import { Container, i18n } from '../../../../symphony';

import { useIsVisibleHook } from '../../../../shared/public/SlHooks/useIsVisibleHook';
import { SectionInView } from '../../utils/SectionInView';
import {
	ActionType,
	HomeSections,
	IHeaderProps,
	RequestType,
	SlHomeActionTypes,
} from '../../global-interfaces';
import { TextHighlighter } from '../shared/TextHighlighter/TextHighlighter';

import s from './HeaderTest.scss';
import { LocalizedLink } from '../../../../shared/public/LocalizedLink/LocalizedLink';
import { ISlLoginResponse, SlLogin } from '../../../../login/public/SlLogin/SlLogin';
import { SlHomeContext } from '../../home-context';
import { SlHomeActionsService } from '../../services/sl-home-actions.service';
import { Social } from '../../../../api/private/global.interface';
// import { getExperimentWithoutExposing } from '../../../../shared/public/experimentsHandler/experimentsHandler';
// import { webHomeFirstScreenExpKey } from '../../global-constants';
import { SlActions } from '../../services/ActionsService/sl-actions';

export function HeaderTest(props: IHeaderProps): JSX.Element {
	useStyles(s);
	const {
		title, description, highlightedText, ctaName, sectionType,
	} = props;
	const element = useRef<HTMLDivElement>(null);
	const { actions$ } = Container.take(SlHomeContext, SlActions);
	SectionInView(element, 'header', HomeSections.header);
	const isVisible = useIsVisibleHook(element, 0, true, '0px');
	// const homeFirstScreenExpData = getExperimentWithoutExposing(webHomeFirstScreenExpKey);
	// const localizationExpKey = homeFirstScreenExpData.key === 'control' ? '' : `-${homeFirstScreenExpData.key}`;
	const localizationExpKey = '-test1';
	useEffect(() => {
		const { callback } = props;
		if (callback) callback(isVisible);
	}, [isVisible]);

	const loginCallback = (loginResponse: ISlLoginResponse) => {
		const { actions } = Container.take(SlHomeContext, SlHomeActionsService);
		actions.next({
			requestType: 'parallel',
			type: SlHomeActionTypes.loginCallBack,
			loginResponse,
		});
	};

	const onCtaClick = (): void => {
		actions$.next({
			type: ActionType.OnCtaClick,
			requestType: RequestType.Parallel,
			data: {
				ctaName,
				sectionType,
			},
		});
	};

	return (
		<section
			ref={element}
			className="sl-home-header"
			sl-test-data="cmpHomeHeader"
		>
			<div
				sl-test-data="cmpControlGroupHeader"
				className="sl-home-header__container"
			>
				<div className="sl-home-header__title-wrapper">
					<div className="sl-home-header__title-icon sl-home-header__title-icon--left">
						<SlIcon iconId="homeSparkleV2" />
					</div>
					<div className="sl-home-header__title">
						<h1>{i18n.t(title + localizationExpKey)}</h1>
						<div className="sl-home-header__info">
							<TextHighlighter
								fullStr={i18n.t(description + localizationExpKey)}
								highlightedStr={i18n.t(highlightedText + localizationExpKey)}
								styles={{ color: '#fff', 'font-weight': '500' }}
							/>
						</div>
					</div>
					<div className="sl-home-header__title-icon sl-home-header__title-icon--right">
						<SlIcon iconId="homeSparkleV1" />
					</div>
				</div>

				<div className="sl-home-header__action">
					<div className="sl-home-header__action__google">
						<SlLogin
							loginType="social"
							socials={[Social.Google]}
							isGoogleNewVersion
							configs={{
								useMock: false,
								disableScrollToTop: true,
								isTitleH1: false,
							}}
							useRouter={false}
							loginCallback={loginCallback}
						/>
					</div>
					<div className="sl-home-header__action__divider">
						{i18n.t('web-home.header-divider')}
					</div>
					<Button
						buttonType="secondary"
						asChild
						className="sl-home-header__action__more-options"
						onClick={onCtaClick}
					>
						<LocalizedLink to="/users/login">
							{i18n.t('web-home.header-signup-cta')}
						</LocalizedLink>
					</Button>
				</div>
			</div>
		</section>
	);
}
