import React, { memo } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';

import s from './AvatarCircle.scss';

interface IAvatarCircleProps {
	size: number;
	imgUrl?: string;
}

export const AvatarCircle: React.FC<IAvatarCircleProps> = memo(
	({ size = 32, imgUrl }) => {
		useStyles(s);

		return (
			<div
				className="sl-home-avatar"
				style={
					{
                    	'--avatar-box-size': `${size}px`,
					} as React.CSSProperties
				}
			>
				{imgUrl ? (
					<img
						src={imgUrl}
						className="sl-home-avatar__img"
						alt="avatar"
						loading="lazy"
					/>
				) : null}
			</div>
		);
	},
);
