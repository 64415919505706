import React from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';

import {
	SlIcon,
	useIcons,
	spearLeft,
	spearRight,
	fbCircle,
	playMarket,
	stars,
	edTech,
} from '@sololearnorg/icons';

import s from './SlAwards.scss';

export interface ISlAwardsProps {
    title: string;
    description: string;
    icon: string;
    iconStyle?: React.CSSProperties;
}

export const SlAwards: React.FC<ISlAwardsProps> = React.memo(
	({
		title, description, icon, iconStyle,
	}) => {
		useStyles(s);
		// Dont forget to add new icons
		useIcons([spearLeft, spearRight, fbCircle, playMarket, stars, edTech]);

		return (
			<div className="sl-awards">
				<div
					className="sl-awards__main-icon"
					style={iconStyle && { ...iconStyle }}
				>
					<SlIcon iconId={icon} />
				</div>
				<div className="sl-awards__content">
					<div className="sl-awards__content-icon">
						<SlIcon iconId="spearLeft" />
					</div>
					<div className="sl-awards__content-text">
						<p>{title}</p>
						<span>{description}</span>
					</div>
					<div className="sl-awards__content-icon">
						<SlIcon iconId="spearRight" />
					</div>
				</div>
			</div>
		);
	},
);
