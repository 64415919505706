import React, { useEffect, useRef, useState } from 'react';
import { fromEvent, Subject } from 'rxjs';
import { debounceTime, takeUntil, tap } from 'rxjs/operators';
import useStyles from 'isomorphic-style-loader/useStyles';

import s from './SlHorizontalScrollList.scss';

export interface ISlHorizontalScrollCommand {
    direction: 'left' | 'right';
}

export interface ISlHorizontalScrollState {
    hasLeftScroll: boolean;
    hasRightScroll: boolean;
}

export interface ISlHorizontalScrollListProps {
    children?: React.ReactNode[] | React.ReactNode;
    width?: string;
    scroll?: ISlHorizontalScrollCommand;
    onStateChange?: (state: ISlHorizontalScrollState) => void;
    itemsCount?: number;
}

export const SlHorizontalScrollList: React.FC<ISlHorizontalScrollListProps> = React.memo(({
	children, width, scroll, onStateChange, itemsCount,
}) => {
    	useStyles(s);
    	const [scrollState, setScrollState] = useState<ISlHorizontalScrollState>();

    	const container = useRef<HTMLDivElement>();

    	const defineState = (
    		scrollLeft: number,
    		scrollWidth: number,
    		offsetWidth: number,
	): void => {
    		const state: ISlHorizontalScrollState = {
    			hasLeftScroll: scrollLeft > 0,
    			hasRightScroll:
                    Math.ceil(scrollLeft + offsetWidth) < scrollWidth,
    		};
    		setScrollState(state);
    		if (onStateChange) {
    			onStateChange(state);
    		}
    	};

    	const subscribeToScroll = (unsubscriber: Subject<void>): void => {
    		fromEvent(container.current, 'scroll')
    			.pipe(
    				takeUntil(unsubscriber),
    				debounceTime(100),
    				tap(() => {
    					defineState(
    						container.current.scrollLeft,
    						container.current.scrollWidth,
    						container.current.offsetWidth,
					);
    				}),
			)
    			.subscribe();
    	};

    	const defineRightAndLeftElements = (): {
            left: HTMLDivElement;
            right: HTMLDivElement;
        } => {
    		const childrenArray = Array.from(
    			container.current.children,
		) as HTMLDivElement[];
    		for (let i = 0; i < childrenArray.length; i++) {
    			if (
    				container.current.scrollLeft - childrenArray[i].offsetLeft
                    < 2
    			) {
    				return {
    					left: childrenArray[i - 1],
    					right: childrenArray[i + 1],
    				};
    			}
    			if (
    				childrenArray[i].offsetLeft > container.current.scrollLeft
    			) {
    				return {
    					left: childrenArray[i - 1],
    					right: childrenArray[i],
    				};
    			}
    		}
    		return null;
    	};

    	const scrollTo = (direction: 'right' | 'left'): void => {
    		const elements = defineRightAndLeftElements();
    		container.current.scrollTo({
    			left: elements[direction]?.offsetLeft,
    			behavior: 'smooth',
    		});
    	};

    	useEffect(() => {
    		const unsubscriber = new Subject<void>();
    		subscribeToScroll(unsubscriber);
    		defineState(
    			container.current.scrollLeft,
    			container.current.scrollWidth,
    			container.current.offsetWidth,
		);
    		return () => {
    			unsubscriber.next();
    			unsubscriber.complete();
    		};
    	}, []);

    	useEffect(() => {
    		if (!scroll) {
    			return;
    		}
    		if (scroll.direction === 'left' && scrollState.hasLeftScroll) {
    			scrollTo('left');
    		} else if (
    			scroll.direction === 'right'
                && scrollState.hasRightScroll
    		) {
    			scrollTo('right');
    		}
    	}, [scroll]);

    	useEffect(() => {
    		if (Number.isInteger(itemsCount)) {
    			defineState(
    				container.current.scrollLeft,
    				container.current.scrollWidth,
    				container.current.offsetWidth,
			);
    		}
    	}, [itemsCount]);

    	return children ? (
	<div
			ref={container}
			style={{ width }}
			className="sl-horizontal"
			sl-test-data="cmpHorizontalScrollList"
		>
			{Array.isArray(children) ? (
    				children.map((child, i) => (
	<div key={i} className="sl-horizontal__item">
						{child}
					</div>
    				))
    			) : (
	<div className="sl-horizontal__item">{children}</div>
    			)}
		</div>
    	) : null;
});
