const generateNum = (max: number): number => Math.floor(Math.random() * max);

export function generateRandomArray(
	initialArr: string[],
	size?: number,
): string[] {
	const newArrlength = size > initialArr.length ? initialArr.length : size;
	const uniqueArr = new Set();

	do {
		const randomNum = generateNum(initialArr.length);
		uniqueArr.add(initialArr[randomNum]);
	} while (uniqueArr.size !== newArrlength);

	return Array.from(uniqueArr) as string[];
}

export function getFingerprintFromCookies(): string {
	const regexp = /(?:(?:^|.*;\s*)fngprntId\s*=\s*([^;]*).*$)|^.*$/;
	return document.cookie.replace(regexp, '$1');
}

export function getAfUserIdFromCookies(): string {
	const regexp = /(?:(?:^|.*;\s*)afUserId\s*=\s*([^;]*).*$)|^.*$/;
	return document.cookie.replace(regexp, '$1');
}

export function getCookie(name): string {
	const value = `; ${document.cookie}`;
	const parts = value.split(`; ${name}=`);
	if (parts.length === 2) {
		return parts.pop().split(';').shift();
	}
	return null;
}
