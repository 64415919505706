import React, {
	memo, useEffect, useRef, useState,
} from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';

import { TextHighlighter } from '../shared/TextHighlighter/TextHighlighter';
import s from './DreamJob.scss';
import { SectionInView } from '../../utils/SectionInView';
import { HomeSections } from '../../global-interfaces';
import { IHomeDreamJobSection } from '../../../../api/public/home-api';

interface IDreamJobProps extends IHomeDreamJobSection {}

export const DreamJob: React.FC<IDreamJobProps> = memo(
	({
		leftText, rightText, benefits, textHighlighted,
	}) => {
		useStyles(s);
		const [activeIndex, setActiveIndex] = useState<number>(0);
		const element = useRef<HTMLDivElement>(null);
		SectionInView(element, 'dreamjob', HomeSections.dreamJob);

		useEffect(() => {
			const timeout = setInterval(() => {
				setActiveIndex((val) => {
					if (val === benefits.length - 1) {
						return 0;
					}
					return val + 1;
				});
			}, 1500);

			return () => {
				clearInterval(timeout);
			};
		}, []);

		return (
			<section className="sl-home-dream-job" ref={element}>
				<div className="sl-home-dream-job__text-container">
					<span>{leftText}</span>
					<div className="sl-home-dream-job__anim-content">
						{benefits?.map((el, index) => (
							<span
								className={
									index === activeIndex
										? 'sl-home-dream-job__animated-item'
										: ''
								}
								key={index}
							>
								{index === activeIndex ? el : null}
							</span>
						))}
					</div>
					<TextHighlighter
						fullStr={rightText}
						highlightedStr={textHighlighted}
						styles={{ color: '#2493df' }}
					/>
				</div>
			</section>
		);
	},
);
