import { RefObject, useEffect } from 'react';

import { Container } from '../../../symphony';

import { useIsVisibleHook } from '../../../shared/public/SlHooks/useIsVisibleHook';
import { SlHomeContext } from '../home-context';
import { SlHomeTrackingService } from '../services/sl-home-tracking.service';
import { SlHomeDataService } from '../services/sl-home-data.service';
import { HomeSections } from '../global-interfaces';

export const SectionInView = (
	ref: RefObject<HTMLElement>,
	track: string,
	el: HomeSections | string,
) => {
	const isVisible = useIsVisibleHook(ref, 0, false, '80% 0px 0px');

	useEffect(() => {
		const { trackAction } = Container.take(
			SlHomeContext,
			SlHomeTrackingService,
		);

		const { sectionsViewTrackingStream } = Container.take(
			SlHomeContext,
			SlHomeDataService,
		);

		if (!sectionsViewTrackingStream.value[el] && isVisible) {
			trackAction.next({
				action: 'view',
				element: `onboarding_landingpage_section_${track}`,
			});

			sectionsViewTrackingStream.next({
				...sectionsViewTrackingStream.value,
				[el]: true,
			});
		}
	}, [isVisible]);
};
