import React, { memo, useEffect } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import { Button } from '@sololearnorg/sol';

import { Container, i18n } from '../../../../../symphony';

import { TextHighlighter } from '../TextHighlighter/TextHighlighter';
import { SlHomeContext } from '../../../home-context';
import { SlActions } from '../../../services/ActionsService/sl-actions';
import {
	ActionType, CtaName, RequestType, SectionType,
} from '../../../global-interfaces';
import { MobileStore, SlMobileStores } from '../../../../../shared/public/SlMobileStores/SlMobileStores';
import { AppStore } from '../../../../../shared/public/globalInterfaces/globalInterfaces';
import { LocalizedLink } from '../../../../../shared/public/LocalizedLink/LocalizedLink';

import s from './GridCard.scss';

export interface IGridCardLink {
	text: string;
	href: string;
}

export interface IGridCardProps {
	size: string;
	icon: string;
	title: string;
	description: string;
	highlightedText?: string;
	cta?: string;
	ctaUrl?: string;
	ctaType?: string;
	ctaName?: CtaName;
	sectionType?: SectionType;
}

export const GridCard: React.FC<IGridCardProps> = memo(
	({
		size = 'medium', icon, title, description, highlightedText, ctaUrl, cta, ctaType, ctaName, sectionType,
	}) => {
		useStyles(s);

		useEffect(() => {
			if (size === 'large') {
				const displayQR = () => {
					(window as any).AF_SMART_SCRIPT.displayQrCode('qr_code_store');
				};

				if ((window as any).AFSmartScriptLoaded) {
					displayQR();
				} else {
					window.addEventListener('AFSmartScriptLoaded', displayQR);
				}

				return () => {
					window.removeEventListener('AFSmartScriptLoaded', displayQR);
				};
			}
		}, []);

		const ctaClickHandler = () => {
			const { actions$ } = Container.take(SlHomeContext, SlActions);

			actions$.next({
				type: ActionType.OnCtaClick,
				requestType: RequestType.Parallel,
				data: {
					ctaName,
					sectionType,
				},
			});
		};

		const handleAppStoreClick = (store: MobileStore) => {
			const { actions$ } = Container.take(SlHomeContext, SlActions);
			const storeMap = {
				[MobileStore.AppStore]: AppStore.AppleStore,
				[MobileStore.PlayMarket]: AppStore.PlayStore,
			};

			actions$.next({
				type: ActionType.OnAppStoreClick,
				requestType: RequestType.Parallel,
				data: {
					store: storeMap[store],
				},
			});
		};

		if (size === 'large') {
			return (
				<div
					className="sl-home-grid-card__big"
					sl-test-data="cmpGridCardLarge"
				>
					<div className="sl-home-grid-card__big__content">
						<div className="sl-home-grid-card__big__text-block">
							<p className="sl-home-grid-card__title sl-home-grid-card__big__title">
								{title}
							</p>
							<div className="sl-home-grid-card__description">
								{highlightedText ? (
									<TextHighlighter
										fullStr={description}
										highlightedStr={highlightedText}
										styles={{ 'font-weight': '600' }}
									/>
								) : (
									<p>{description}</p>
								)}
							</div>
						</div>
						<div className="sl-home-grid-card__app-container">
							<div className="sl-home-grid-card__qr-label">{i18n.t('web-home.qr-label')}</div>
							<div className="sl-home-grid-card__icons-and-qr-wrapper">
								<div id="qr_code_store" className="sl-home-grid-card__qr" />
								<div className="sl-home-grid-card__icons-wrapper">
									<SlMobileStores
										onStoreClick={handleAppStoreClick}
										classNames={{
											appStore:
												'sl-home-grid-card__social-icon app-store',
											playMarket:
												'sl-home-grid-card__social-icon play-market',
										}}
									/>
								</div>
							</div>
						</div>
					</div>
					<div className="sl-home-grid-card__big__image-wrapper">
						<img
							className="sl-home-grid-card__image"
							src={icon}
							alt="illustration"
							loading="lazy"
						/>
					</div>
				</div>
			);
		}

		return (
			<div className="sl-home-grid-card__medium">
				<div className="sl-home-grid-card__medium__image-wrapper">
					<img
						className="sl-home-grid-card__image"
						src={icon}
						alt="illustration"
						loading="lazy"
					/>
				</div>
				<div className="sl-home-grid-card__medium__content">
					<p className="sl-home-grid-card__title sl-home-grid-card__medium__title">
						{title}
					</p>
					<div className="sl-home-grid-card__description">
						{highlightedText ? (
							<TextHighlighter
								fullStr={description}
								highlightedStr={highlightedText}
								styles={{ 'font-weight': '600' }}
							/>
						) : (
							<p>{description}</p>
						)}
					</div>
					{
						cta && (
							<Button buttonType={ctaType ?? 'primary'} asChild className="sl-home-grid-card__cta" onClick={() => { ctaClickHandler(); }}>
								<LocalizedLink to={ctaUrl}>
									{cta}
								</LocalizedLink>
							</Button>
						)
					}
				</div>
			</div>
		);
	},
);
