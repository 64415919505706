import React, {
	memo, useMemo, useRef, useState,
} from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';

import { Container, i18n } from '../../../../symphony';

import { ISlHorizontalScrollCommand, ISlHorizontalScrollState, SlHorizontalScrollList } from '../../../../shared/public/SlHorizontalScrollList/SlHorizontalScrollList';
import { SlSliderArrows } from '../../../../shared/public/SlSliderArrows/SlSliderArrows';
import { CourseItem, ICourseData } from '../shared/CourseItemTest/CourseItemTest';
import { HomeSections } from '../../global-interfaces';
import { SlHomeContext } from '../../home-context';
import { SlHomeTrackingService } from '../../services/sl-home-tracking.service';
import { SectionInView } from '../../utils/SectionInView';

import s from './CoursesTest.scss';

export interface ICoursesProps {
	courses: ICourseData[];
}

export const CoursesTest: React.FC<ICoursesProps> = memo(({ courses }) => {
	useStyles(s);
	const [scrollState, setScrollState] = useState<ISlHorizontalScrollState>({
		hasLeftScroll: false,
		hasRightScroll: false,
	});
	const orderedCourses = useMemo(() => courses.sort((a, b) => {
		if (b.uiConfigurations?.isTop !== a.uiConfigurations?.isTop) {
			return a.uiConfigurations?.isTop ? -1 : 1;
		}

		return a.orderNumber - b.orderNumber;
	}), [courses]);

	const [direction, setDirection] = useState<ISlHorizontalScrollCommand>();

	const element = useRef<HTMLDivElement>(null);
	SectionInView(element, 'courses', HomeSections.courses);

	const { trackAction } = Container.take(
		SlHomeContext,
		SlHomeTrackingService,
	);

	const onCourseItem = (id: number) => () => {
		trackAction.next({
			action: 'select',
			element: 'onboarding_landingpage_course',
			entityId: id,
		});
	};

	const directionHandler = (data: ISlHorizontalScrollCommand) => {
		const trackElement = data.direction === 'right'
			? 'onboarding_landingpage_scrollcoursesright'
			: 'onboarding_landingpage_scrollcoursesleft';
		trackAction.next({
			action: 'click',
			element: trackElement,
		});
		setDirection(data);
	};

	return (
		<section className="sl-home-courses" ref={element}>
			<div className="sl-home-courses__container">
				<h2>{i18n.t('web-home.course-title')}</h2>
				<div className="sl-home-courses__container__courses">
					<SlSliderArrows
						cssClass="slider-arrows-margin"
						isShown={courses.length > 1}
						scrollState={scrollState}
						directionHandler={directionHandler}
					/>
					<SlHorizontalScrollList
						scroll={direction}
						onStateChange={setScrollState}
					>
						{orderedCourses.map((el: ICourseData) => (
							<CourseItem
								onItemClick={onCourseItem(el?.id)}
								data={el}
								key={el.id || el.alias}
							/>
						))}
					</SlHorizontalScrollList>
				</div>
			</div>
		</section>
	);
});
