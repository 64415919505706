import { BehaviorSubject } from 'rxjs';
import { Service } from '../../../symphony';
import { ISectionsDisabledActions } from '../global-interfaces';
import { IHomeData } from '../../../api/public/home-api';

@Service()
export class SlHomeDataService {
	public homeDataStream = new BehaviorSubject<IHomeData>(null);

	public sectionsViewTrackingStream = new BehaviorSubject<ISectionsDisabledActions>({});

	public experimentsStream = new BehaviorSubject<{
		[experimentName: string]: boolean;
	}>(null);

	public emailInputValueStream = new BehaviorSubject<string>('');

	public isShownSignUpModal$ = new BehaviorSubject<boolean>(false);
}
