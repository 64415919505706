import React, { useEffect, useRef } from 'react';
import { forkJoin, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AuthService, Container, history } from '../../../symphony';

import { SlHomeContext } from '../../private/home-context';
import { HomeContainer } from '../../private/containers/HomeContainer/HomeContainer';
import { SlHomeActionsService } from '../../private/services/sl-home-actions.service';
import { SlHomeTrackingService } from '../../private/services/sl-home-tracking.service';
import { SlActionsService } from '../../private/services/ActionsService/sl-actions.service';
import { useComponentWillMount } from '../../../shared/public/SlUtils/useComponentWillMount';

export function SlHome(): JSX.Element {
	const unsubscriber = useRef(new Subject<void>());

	useComponentWillMount(() => {
		const { actionsStream } = Container.take(
			SlHomeContext,
			SlHomeActionsService,
		);
		const { trackActionStream, appsFlyerStream } = Container.take(
			SlHomeContext,
			SlHomeTrackingService,
		);
		Container.register(SlHomeContext, 'unsubscriber', unsubscriber.current);
		Container.take(SlHomeContext, SlActionsService);

		forkJoin([actionsStream, trackActionStream, appsFlyerStream])
			.pipe(takeUntil(unsubscriber.current))
			.subscribe();
	});

	useEffect(() => {
		const authService = Container.take('global', AuthService);

		if (authService.isLoggedIn()) {
			history.replace(`/profile/${authService.getUser().id}`);
		}
		return () => {
			unsubscriber.current.next();
			unsubscriber.current.complete();
			Container.reset(SlHomeContext);
		};
	}, []);

	return (
		<div className="sl-home">
			<HomeContainer />
		</div>
	);
}
