import React, { memo } from 'react';
import { SlIcon } from '@sololearnorg/icons';
import useStyles from 'isomorphic-style-loader/useStyles';

import s from './InfoBox.scss';

interface IInfoBoxProps {
	className: string;
	iconSize?: string;
	text?: string;
	iconId?: string;
	imageUrl?: string;
}

export const InfoBox: React.FC<IInfoBoxProps> = memo(
	({
		text, iconId, iconSize, className, imageUrl,
	}) => {
		useStyles(s);

		return (
			<div
				className={`sl-home-info-box ${
					className ? `sl-home-info-box--${className}` : ''
				}`}
			>
				{iconId && (
					<div
						className={`sl-home-info-box__icon sl-home-info-box__icon--${
							iconSize || ''
						}`}
					>
						<SlIcon iconId={iconId} />
					</div>
				)}

				{imageUrl && (
					<div className="sl-home-info-box__image-box">
						<img src={imageUrl} alt="course" loading="lazy" />
					</div>
				)}

				{text && <p className="sl-home-info-box__text">{text}</p>}
			</div>
		);
	},
);
