import React, { memo, useCallback, useRef } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';

import { Container, i18n } from '../../../../symphony';

import { SectionInView } from '../../utils/SectionInView';
import { ActionType, HomeSections, RequestType } from '../../global-interfaces';
import { SlHomeContext } from '../../home-context';
import { SlActions } from '../../services/ActionsService/sl-actions';

import s from './SignUp2.scss';
import { ISlLoginResponse, SlLogin } from '../../../../login/public/SlLogin/SlLogin';
import { IHomeSignUp2Section } from '../../../../api/public/home-api';

interface ISignUp2Props extends IHomeSignUp2Section {}

export const SignUp2: React.FC<ISignUp2Props> = memo(
	({ title, background }) => {
		useStyles(s);
		const element = useRef<HTMLDivElement>(null);
		SectionInView(element, 'signup2', HomeSections.signUp2);

		const loginCallback = useCallback((loginResponse: ISlLoginResponse) => {
			const { actions$ } = Container.take(SlHomeContext, SlActions);
			actions$.next({
				type: ActionType.LoginCallback,
				requestType: RequestType.Parallel,
				data: {
					loginResponse,
				},
			});
		}, []);

		return (
			<section
				className="sl-home-sign-up2"
				sl-test-data="cmpSignUpSecond"
				style={{
					background: background || '',
				}}
				ref={element}
			>
				<div className="sl-home-sign-up2__container">
					<h2 className="sl-home-sign-up2__title">{title}</h2>
					<div className="sl-home-sign-up2__signup-wrapper">
						<SlLogin
							configs={{
								useMock: null,
								disableScrollToTop: true,
								isTitleH1: false,
							}}
							useRouter={false}
							loginCallback={loginCallback}
							defaultRoute="signup"
							loginType="full"
							signupTitle={i18n.t('web-auth.signup-title')}
							cssClass="sign-up-container"
							trackingPrefix="home"
						/>
					</div>
				</div>
			</section>
		);
	},
);
