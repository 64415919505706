import React from 'react';

interface ITextHighlighterProps {
	fullStr?: string;
	highlightedStr?: string;
	styles: React.CSSProperties;
}

export function TextHighlighter({
	fullStr,
	highlightedStr,
	styles,
}): React.ReactElement<ITextHighlighterProps> {
	const styleString = Object.keys(styles).reduce(
		(style, current, index, stylesList) => `${style}${current}:${styles[current]}${
			index === stylesList.length - 1 ? '' : ';'
		}`,
		'',
	);

	const text = fullStr.replace(
		highlightedStr,
		`<span style='${styleString}'>${highlightedStr}</span>`,
	);

	return (
		<p
			dangerouslySetInnerHTML={{
				__html: text,
			}}
		/>
	);
}
