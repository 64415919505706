export const communityAvatars = [
	'https://assets.sololearn.com/community-user-1.png',
	'https://assets.sololearn.com/community-user-2.png',
	'https://assets.sololearn.com/community-user-3.png',
	'https://assets.sololearn.com/community-user-4.png',
	'https://assets.sololearn.com/community-user-5.png',
	'https://assets.sololearn.com/community-user-6.png',
	'https://assets.sololearn.com/community-user-7.png',
	'https://assets.sololearn.com/community-user-8.png',
	'https://assets.sololearn.com/community-user-9.png',
	'https://assets.sololearn.com/community-user-10.png',
	'https://assets.sololearn.com/community-user-11.png',
	'https://assets.sololearn.com/community-user-12.png',
	'https://assets.sololearn.com/community-user-13.png',
	'https://assets.sololearn.com/community-user-14.png',
	'https://assets.sololearn.com/community-user-15.png',
	'https://assets.sololearn.com/community-user-16.png',
	'https://assets.sololearn.com/community-user-17.png',
	'https://assets.sololearn.com/community-user-18.png',
	'https://assets.sololearn.com/community-user-19.png',
	'https://assets.sololearn.com/community-user-20.png',
	'https://assets.sololearn.com/community-user-21.png',
	'https://assets.sololearn.com/community-user-22.png',
	'https://assets.sololearn.com/community-user-23.png',
	'https://assets.sololearn.com/community-user-24.png',
];
